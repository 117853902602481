import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from "@/assets/scripts/helper";
import { Roles } from "./Enumeradores";

export class PageBase extends Vue {
  public noSpecialRules = [
    (v) => !!v || "Campo é obrigatório",
    (v) =>
      !/[záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+$/.test(v) ||
      "Não é permitido caracteres especiais",
  ];
  public emailRules = [
    (v) =>
      (!!v &&
        /^([a-z.-_]|[0-9])+@([a-z-]|[0-9])+(\.[a-z]{2,3})?(\.[a-z]{2,3})$/i.test(
          v
        )) ||
      "E-mail precisa ser válido",
  ];
  public fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  public numberFieldRules: any[] = [
    (v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório",
  ];
  public diaMesRules: any[] = [
    (v) =>
      (!!v && /^(?:[1-9]|[12]\d|3[01])$/.test(v)) ||
      "Dia bom deve ser entre 1 e 31",
  ];

  public loading: boolean = false;

  public roles = Roles;

  public sessionApp: any;
  public app: {
    funcionarioId: number;
    nome: string;
    email: string;
    perfilId: number;
    temaEscuro: boolean;
  } = {
    funcionarioId: 0,
    nome: "",
    email: "",
    perfilId: 0,
    temaEscuro: false
  };

  constructor() {
    super();

    if (!localStorage.sessionApp) {
      this.Deslogar();
      return;
    }

    this.sessionApp = JSON.parse(localStorage.sessionApp);

    this.app.funcionarioId = this.sessionApp.dados.funcionarioId;
    this.app.nome = this.sessionApp.dados.nome;
    this.app.email = this.sessionApp.dados.email;
    this.app.perfilId = this.sessionApp.dados.perfilId;
    this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
  }

  ExportarExcel(tabela: string, worksheet?: string) {
    TableToExcel(tabela, worksheet);
  }

  JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean) {
    JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
  }

  MudarTema(temaEscuro: boolean){
      this.app.temaEscuro = temaEscuro;
      this.sessionApp.dados.temaEscuro = temaEscuro;
      localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
      this.$vuetify.theme.dark = temaEscuro;
  }

  Deslogar() {
    localStorage.removeItem("sessionApp");
    this.$router.push({ name: "Login" });
  }

  GetAppVersion() {
    const pack = require("../../../../package.json");
    return pack.version;
  }
}
