
import { PageBase } from '@/core/models/shared/PageBase';
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Roles } from "@/core/models/shared/Enumeradores"
import { FuncionarioService } from '@/core/services/geral';
import { AlertSimpleErr } from '@/core/services/shared';

@Component
export default class ListaFuncionario extends PageBase{    
    @Prop() titulo!: string;
    @Prop() icone!: string;
    @Prop({default: false}) overlay!: boolean;

    menuOpts: any[] = [];
    drawer: boolean = false;
    dialogAlterarSenha: boolean = false;
    
    roles = Roles;

    mounted() {
        this.$vuetify.theme.dark = this.app.temaEscuro;
        this.CarregaMenu();        
    }

    CarregaMenu(){
        // EXISTEM DOIS TIPOS DE ITEM -> GRUPO COM ITENS OU APENAS ITEM SOLTO
        // this.menuOpts = [
        //     { 
        //         name:'GRUPO', icon:'mdi-file', restrito: false, 
        //         items: [
        //             { name:'ITEM', icon:'mdi-file-chart', path:'ROTA', restrito: false }                    
        //         ]
        //     },
        //     { name:'ITEM', icon:'mdi-factory', path:'ROTA', restrito: false }
        // ];
        this.menuOpts = [
            { name:'Licenças', icon:'mdi-key', path:'/geral/licenca', restrito: this.RoleRestrita([this.roles.Administrador, this.roles.Operador]) },
            { name:'CoEs', icon:'mdi-crown', path:'/geral/coe', restrito: this.RoleRestrita([this.roles.Administrador, this.roles.Operador]) },
            { name:'Empresas', icon:'mdi-factory', path:'/geral/empresa', restrito: this.RoleRestrita([this.roles.Administrador, this.roles.Operador]) },
            { name:'Usuários', icon:'mdi-account', path:'/geral/usuario', restrito: this.RoleRestrita([this.roles.Administrador, this.roles.Operador]) },
            { name:'Computadores', icon:'mdi-laptop', path:'/geral/computador', restrito: this.RoleRestrita([this.roles.Administrador, this.roles.Operador]) },
            { name:'Funcionários', icon:'mdi-account-group', path:'/geral/funcionario', restrito: this.RoleRestrita([this.roles.Administrador, this.roles.Operador]) },                 
        ]
    }

    RoleRestrita(rolesId: number[]){
        return !rolesId.find(x => x == this.app.perfilId);
    }

    MudarTema(){
        const funcionarioService = new FuncionarioService();
        funcionarioService.AlterarTema().then(
            res => super.MudarTema(res.data),
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Logout(){
        this.Deslogar();
    }
}
