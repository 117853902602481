import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class ComputadorService extends Service {
  constructor() {
    super("/Computador");
  }

  //#region => GED
  LerArquivo(id: number) {
    return http.get(`${this.GetNomeControle()}/${id}/Arquivo`, {
      responseType: "blob",
    });
  }
  GravarArquivo(id: number, tipo: string, nome: string, arquivo: any) {
    let bodyFormData = new FormData();
    var blob = new Blob([arquivo], { type: tipo });
    bodyFormData.append("arquivo", blob, nome);
    return http.post(`${this.GetNomeControle()}/${id}/Arquivo`, bodyFormData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  ExcluirArquivo(id: number) {
    return http.delete(`${this.GetNomeControle()}/${id}/Arquivo`);
  }
  //#endregion => GED
}
