export class ArquivoService {

  async LerDataURL(arquivo: any): Promise<string> {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();

      fr.onload = (arquivo: any) => {
        resolve(arquivo!.target!.result);
      };

      fr.readAsDataURL(arquivo);
    });
  }

  async LerArrayBuffer(arquivo: any): Promise<string> {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();

      fr.onload = (arquivo: any) => {
        resolve(arquivo!.target!.result);
      };

      fr.readAsArrayBuffer(arquivo);
    });
  }

  async DownloadDataUrl(arquivo: any, tipo: string, nome: string) {
    const a = document.createElement("a");
    a.href = await this.LerDataURL(arquivo)
    a.type = tipo;
    a.download = nome;
    a.click();
    a.remove();
  }

  async DownloadArrayBuffer(arquivo: any, tipo: string, nome: string) {
    const a = document.createElement("a");
    a.href = await this.LerArrayBuffer(arquivo)
    a.type = tipo;
    a.download = nome;
    a.click();
    a.remove();
  }  
}