
import { Computador, Usuario } from '@/core/models/geral';
import { CrudBase } from '@/core/models/shared';
import { ComputadorService, UsuarioService } from '@/core/services/geral';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import jiff from 'jiff';
import { ArquivoService } from '@/core/services/shared';

@Component
export default class ListaComputador extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Computador;

    service: ComputadorService = new ComputadorService();
    itemOriginal!: Computador;
    $refs!: {
        form: HTMLFormElement
    }

    UsuarioService: UsuarioService = new UsuarioService();
    Usuario: Usuario[] = [];

    arquivoNome: string = "";
    arquivo: any = null;
    arquivoGravar: any = null;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.arquivoNome = this.item.workstationId + ".hid";
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    LerArquivo(id: Computador){
        this.service.LerArquivo(this.item.id).then(
            res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.item.workstationId}.hid`);
                document.body.appendChild(link);
                link.click();
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }
    async GerarArquivo(){
        return await new ArquivoService().LerArrayBuffer(this.arquivo);
    };
    ExcluirArquivo(id: Computador){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                context.service.ExcluirArquivo(context.item.id).then(
                    res => {
                        AlertSimpleRes("Aviso!", res);
                        context.item.hasFile = false;
                    },
                    err => AlertSimpleErr("Aviso!", err)
                );
            });
        }
        AlertExcludeQuestion(excluir);
    }

    Carregar(){
        this.UsuarioService.ListarTudo().then(
            res => {
                this.Usuario = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if(this.$refs.form.validate()){
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    if(this.arquivo){
                        let id = this.item.id > 0 ? this.item.id : res.data.id;
                        this.GerarArquivo().then(
                            res => {
                                this.arquivoGravar = res;
                                this.service.GravarArquivo(id, this.arquivo.type, this.arquivo.name, this.arquivoGravar).then(
                                    res => {
                                        AlertSimpleRes("Aviso!", res);                
                                        this.Salvou();
                                    },
                                    err => AlertSimpleErr("Aviso!", err)
                                )
                            },
                            err => AlertSimpleErr("Aviso!", err)
                        );                                       
                    }
                    else {
                        AlertSimpleRes("Aviso!", res);                
                        this.Salvou();
                    }
                },
                err => {
                    AlertSimpleErr("Aviso!", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.Limpar();
        this.dialog = false;
    }
    
    Limpar(){
        this.arquivoNome = "";
        this.arquivo = null;
        this.arquivoGravar = null;
    }
}
