import Vue from 'vue';
import Vuetify, { colors } from 'vuetify/lib';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    options:{
      customProperties: true
    },
    dark: false,
    default: 'light',
    themes: { 
      light: {
        primary: '#E90024',
        secondary: '#5C656D',
        chipGreen: colors.green.lighten5,
        chipFontGreen: colors.green.lighten2,
        chipRed: colors.red.lighten5,
        chipFontRed: colors.red.lighten2,
        chipBlue: colors.blue.lighten5,
        chipFontBlue: colors.blue.lighten2,
        chipYellow: colors.yellow.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: colors.lime.darken3,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.darken2
      },
      dark: {
        primary: '#B5001C',
        secondary: '#5C656D',
        chipGreen: colors.green.lighten3,
        chipFontGreen: colors.green.darken2,
        chipRed: colors.red.lighten3,
        chipFontRed: colors.red,
        chipBlue: colors.blue.lighten3,
        chipFontBlue: colors.blue.accent2,
        chipYellow: colors.amber.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: colors.lime.darken3,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.darken2
      }
    },
  }
});