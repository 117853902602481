import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "*", redirect: "/login" },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/shared/Login.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/shared/Home.vue"),
  },
  {
    path: "/geral/funcionario",
    name: "Funcionario",
    component: () => import("../views/geral/funcionario/Lista.vue"),
  },
  {
    path: "/geral/coe",
    name: "Coe",
    component: () => import("../views/geral/coe/Lista.vue"),
  },
  {
    path: "/geral/empresa",
    name: "Empresa",
    component: () => import("../views/geral/empresa/Lista.vue"),
  },
  {
    path: "/geral/usuario",
    name: "Usuario",
    component: () => import("../views/geral/usuario/Lista.vue"),
  },
  {
    path: "/geral/computador",
    name: "Computador",
    component: () => import("../views/geral/computador/Lista.vue"),
  },
  {
    path: "/geral/licenca",
    name: "Licença",
    component: () => import("../views/geral/licenca/Lista.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
