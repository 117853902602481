import {http, httpHeader} from '@/core/ApiConfig';
import { LoginModel } from '@/core/models/shared/LoginModel';
   
export class AutenticadorService {

    private _nomeControle = 'autenticador';

    public async Autenticar(model: LoginModel){
        return await http.post(`${this._nomeControle}/login`, model);
    }

    // MudarTema(id: number){
    //     return http.put(`${this._nomeControle}/${id}/MudarTema`, null);
    // }
}