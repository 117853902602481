<template>    
    <canvas :id="key"/>    
</template>

<script>
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    props:{
        data: {
            required: true
        },
        headers: {
            required: true
        }
    },
    created(){
        this.key = Math.floor(Math.random() * 9999999999).toString();
        Chart.register(...registerables);
        Chart.register(ChartDataLabels);
    },
    mounted(){
        this.Generate();
        this.UpdateChart(); 
    },
    watch:{
        data: function (){
            this.UpdateChart();
        }
    },
    data: () => ({
        chart: {},
        key: 'ada4sd8a9sd7',        
        colors: [
            'rgba(75, 192, 192, 0.3)',
            'rgba(255, 99, 132, 0.3)',
            'rgba(135, 206, 235, 0.3)',
            'rgba(205, 133, 63, 0.3)',
            'rgba(128, 128, 128, 0.3)',
            'rgba(0, 139, 139, 0.3)',
            'rgba(154, 205, 50, 0.3)',
            'rgba(54, 162, 235, 0.3)',
            'rgba(244, 164, 96, 0.3)',
            'rgba(0, 128, 0, 0.3)',
            'rgba(255, 206, 86, 0.3)',
            'rgba(70, 130, 180, 0.3)',
            'rgba(218, 165, 32, 0.3)',
            'rgba(153, 102, 255, 0.3)'
        ]
    }),
    methods:{
        Generate(){
            let vm = this;
            const canvas = document.getElementById(this.key);
            const config = {
                type: 'pie',
                options: {
                    cutout:'50%',
                    responsive: true,
                    maintainAspectRatio: false,                    
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: function(context){
                                    // return vm.headers[context[0].datasetIndex];
                                    return context[0].label;
                                },
                                label: function(context){
                                    var dataset = context.chart.data.datasets[0];
                                    var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                        return previousValue + currentValue;
                                    });                                    
                                    var currentValue = dataset.data[context.dataIndex];
                                    var valor = "Valor: " + currentValue.toDecimal(2);
                                    var percentage = "Porcentagem: " + Math.floor(((currentValue / total) * 100) + 0.5) + "%";                                    
                                    return [valor, percentage];
                                },
                                footer: function(context){
                                    // var dataset = context[0].chart.data;
                                    // console.log(dataset);
                                    // var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                    //     return previousValue + currentValue;
                                    // });                                    
                                    // var currentValue = dataset.data[context.dataIndex];
                                    // var valor = "Valor: " + currentValue.toDecimal(2);
                                    // var percentage = "Porcentagem: " + Math.floor(((currentValue / total) * 100) + 0.5) + "%";
                                    // return [valor, percentage];
                                }
                            }
                        },
                        legend: {
                            labels: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'                                
                            },
                            position: 'left'
                        },
                        labels: {
                            render: 'percentage'
                        },
                        datalabels: {                            
                            color: '#000000',
                            anchor: 'center',
                            algin: 'center',
                            clamp: true,
                            formatter: function(value, context) {
                                var dataset = context.chart.data.datasets[0];
                                var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                    return previousValue + currentValue;
                                });
                                var currentValue = dataset.data[context.dataIndex];
                                var percentage = Math.floor(((currentValue / total) * 100) + 0.5);
                                if(percentage > 5)
                                    return value + " (" + percentage + "%)";
                                else
                                    return '';
                            },
                            font: {
                                weight: 'bold',                                
                            },
                            offset: 10,                            
                        }
                    },
                    layout: {
                        padding: 30
                    },
                }
            }
            this.chart = new Chart(canvas, config);
        },
        UpdateChart(){
            if(this.chart){
                this.chart.data.datasets.length = 0;
                this.chart.data.labels = this.headers,
                this.data.forEach(item => {
                    this.chart.data.datasets.push({
                        label: item.nome,
                        data: item.valores,
                        backgroundColor: this.colors,
                        borderWidth: 1
                    });
                });
                this.chart.update();
            }
        }
    }
}
</script>