import Vue from "vue";

//SHARED
import AlterarSenha from "./shared/AlterarSenha.vue";
import Avatar from "./shared/Avatar.vue";
import MasterPage from "./shared/MasterPage.vue";
import TextSearch from "./shared/TextSearch.vue";
import PieChart from "./shared/ChartJs/PieChart.vue"

Vue.component("alterar-senha", AlterarSenha);
Vue.component("avatar-component", Avatar);
Vue.component("master-page", MasterPage);
Vue.component("text-search", TextSearch);
Vue.component("pie-chart", PieChart);
//END SHARED

//GERAL
import CadastroFuncionario from "./geral/funcionario/Cadastro.vue";
import CadastroLicenca from "./geral/licenca/Cadastro.vue";
import CadastroCoe from "./geral/coe/Cadastro.vue";
import CadastroEmpresa from "./geral/empresa/Cadastro.vue";
import CadastroUsuario from "./geral/usuario/Cadastro.vue";
import CadastroComputador from "./geral/computador/Cadastro.vue";

Vue.component("cadastro-funcionario", CadastroFuncionario);
Vue.component("cadastro-licenca", CadastroLicenca);
Vue.component("cadastro-coe", CadastroCoe);
Vue.component("cadastro-empresa", CadastroEmpresa);
Vue.component("cadastro-usuario", CadastroUsuario);
Vue.component("cadastro-computador", CadastroComputador);
//END GERAL
