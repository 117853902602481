import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";
import { AlterarSenhaModel } from "../../models/models/"

export class FuncionarioService extends Service {
    
    constructor() {
        super('/Funcionario');
    }

    AlterarSenha(model: AlterarSenhaModel){
        return http.post(`${this.GetNomeControle()}/AlterarSenha`, model);
    }

    ResetarSenha(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/ResetarSenha`);
    }

    AlterarTema(){
        return http.post(`${this.GetNomeControle()}/AlterarTema`);
    }
}