
import { Coe } from '@/core/models/geral';
import { CrudBase } from '@/core/models/shared';
import { CoeService } from '@/core/services/geral';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Component, Prop, Watch } from 'vue-property-decorator'
import jiff from 'jiff';

@Component
export default class ListaCoe extends CrudBase{    
    @Prop() private value!: string;
    @Prop() item!: Coe;

    service: CoeService = new CoeService();
    itemOriginal!: Coe;
    $refs!: {
        form: HTMLFormElement
    }    

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    Salvar(){
        if(this.$refs.form.validate()){
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso!", res);                
                    this.Salvou();
                },
                err => {
                    AlertSimpleErr("Aviso!", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.dialog = false;
    }
    
}
