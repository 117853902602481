import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class LicencaService extends Service {
  
  constructor() {
    super("Licenca");
  }

  Dashboard(usuarioId?: number, coeId?: number, empresaId?: number, computadorId?: number, tipoId?: number, dataInicial?: string, dataFinal?: string){
    return http.get(`${this.GetNomeControle()}/Dashboard`, {
      params: {
        usuarioId,
        coeId,
        empresaId,
        computadorId,
        tipoId,
        dataInicial,
        dataFinal
      }
    });
  }
}
