import axios, { AxiosHeaders } from "axios";

export const httpHeader = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    include: "",
  },
};
export const http = axios.create({
  //baseURL: process.env.VUE_APP_BASEAPI,
  baseURL: "https://lm.api.jrcbrasil.com/api/",
  //baseURL: "http://127.0.0.1:5223/api/",
  headers: httpHeader.headers,
});

http.interceptors.request.use((config) => {
  if (localStorage.sessionApp) {
    let sessionApp = JSON.parse(localStorage.sessionApp);
    config.headers!.Authorization = "Bearer " + sessionApp.token;
  }

  return config;
});

http.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status == 401) {
      localStorage.removeItem("sessionApp");
      window.location.href = "/login";
    }

    return Promise.reject(err);
  }
);
